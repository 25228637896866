import React, { Component } from 'react'
import ContainerFull from '../../components/atom/container/conatinerfull';
import Image from '../../components/atom/image';

// class NoMatch extends Component {
//   render() {
//     return (
//       <div className="no-match-container">
//         <div className="no-match">
//           Sorry, page not found {"<!>"}
//         </div>
//       </div>
//     )
//   }
// }

class NoMatch extends Component {

  redirectHome = () => {
    window.location.href = process.env.NEXT_PUBLIC_AIF_MODULE_URL;
  }

  render() {
    return (
      <ContainerFull class="bgGreen padd0">
        <div className="no-match-container">
          <div className="no-match">
            <Image image={`../../../assets/images/dash/404_error.svg`} />
            {/* <Image image="dash/404_error.svg" /> */}
            <h4 className='section-heading-text'>PAGE NOT FOUND</h4>
            <p>Unfortunately the page you were looking for could not be found. It may be <br className="textBreak" />temporarily unavailable, moved or no longer exist.</p>
            <button onClick={this.redirectHome} className="primary-blue-button" type="button">Go Back</button>
          </div>
        </div>
      </ContainerFull>
    )
  }
}

export default NoMatch

